<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>预约管理</el-breadcrumb-item>
      <el-breadcrumb-item>时间列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">时间列表</div>
        <div>
          <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box_a">
          <span>名称：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入名称"
            v-model="name"
            clearable
          ></el-input>
        </div>
        <div class="int_box_a">
          <span>日期：</span>
          <el-date-picker
            value-format="yyyy-MM-dd"
            size="small"
            class="int_width"
            v-model="date"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="famous_name" label="名师名称"></el-table-column>
          <el-table-column width="200" prop="famous_teacher_no" label="编号"></el-table-column>
          <el-table-column prop="famous_title" label="职称"></el-table-column>
          <el-table-column prop="appointment_date" label="预约日期"></el-table-column>
          <el-table-column prop="time_slot" label="时间段"></el-table-column>
          <el-table-column prop="famous_tel" label="电话"></el-table-column>
          <el-table-column prop="famous_email" label="邮箱"></el-table-column>
          <el-table-column width="70px" label="操作">
            <template v-slot="scope">
              <el-tooltip :enterable="false" effect="dark" content="删除" placement="top-end">
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 500px; overflow-y: auto">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="名师名字：" prop="famous_no">
              <el-select
                popper-class="selectduo"
                style="width: 450px"
                clearable
                size="small"
                v-model="addform.famous_no"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.famous_no"
                  :label="item.famous_name"
                  :value="item.famous_no"
                >
                </el-option>
                <!-- style="background-color: #fff; padding: 10px 0;min-width: 450px; position: fixed; top: 559px; left: 337px; transform-origin: center top; z-index: 2009;" -->
                <div style="background-color: #fff; padding: 10px 0;min-width: 450px; position: absolute; left: 0;bottom:0; z-index: 3009;">
                  <el-pagination
                    @current-change="handleCurrentChange1"
                    :current-page="page1"
                    background
                    layout="prev, pager, next"
                    :total="total1"
                  >
                  </el-pagination>
                </div>
              </el-select>
            </el-form-item>
            <el-form-item label="日期：" prop="date">
              <el-date-picker
                value-format="yyyy-MM-dd"
                size="small"
                style="width: 450px"
                v-model="addform.date"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="时间段：" prop="slot">
              <el-select
                style="width: 450px"
                clearable
                multiple
                size="small"
                v-model="addform.slot"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item,index) in timeslotList"
                  :key="index"
                  :label="item"
                  :value="index">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      name: '',
      date: '',
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        famous_no: '',
        date: '',
        slot: ''
      },
        timeslotList: [],

      rules: {
        famous_no: [{ required: true, message: '请选择名师名字', trigger: 'blur' }],
        date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        slot: [{ required: true, message: '请选择时间段', trigger: 'blur' }]
      },
      id: '',
      text: '',
      page1:1,
      total1: 0,
      // 名师列表
      teacherList: []
    }
  },
  mounted() {
    this.name = this.$route.query.famous_name || ''
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleCurrentChange1(val) {
      this.page1 = val
      this.teacher()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        famous_no: '',
        date: '',
        slot: ''
      }
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/famous/time/index', {
        params: {
          page: this.page,
          limit: this.limit,
          name: this.name,
          date: this.date
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
    // 名师列表
    async teacher() {
      const { data: res } = await this.$http.get(
        'edulogin.php/famous/teacher/index',
        {
          params: {
            page: this.page1,
            limit: 50,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.teacherList = res.data.list
      this.total1 = res.data.total
    },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.teacher()
      this.timeslot()
      this.__token__()
    },
    // 时间段
    async timeslot() {
      const { data:res} = await this.$http.get('edulogin.php/famous/time/timeslot')
      if(res.code != 1) return this.$message.error(res.msg)
      this.timeslotList = res.data
    },
    // 添加确认
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform.__token__ = this.token
        this.addform.slot = this.addform.slot.join()
        const { data: res } = await this.$http.post(
          'edulogin.php/famous/time/add',
          this.addform
        )
        if (res.code != 1) {
          this.addform.slot = this.addform.slot ? this.addform.slot.split(',') : []
          this.__token__()
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          famous_no: '',
          date: '',
          slot: ''
        }),
          this.index()
      })
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'edulogin.php/famous/time/del',
            {
              params: {
                id: id
              }
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
